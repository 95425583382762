<template>
    <div class="carousel-content">
        <el-scrollbar class="form-content">
            <div class="carousel-title">新增通知</div>
            <el-form ref="addNoticeForm" :model="addNoticeForm" :rules="addNoticeRules" class="carousel-box">
                <el-form-item label="通知标题" prop="title" :label-width="formLabelWidth">
                    <el-input v-model="addNoticeForm.title" placeholder="请输入标题1-30字"></el-input>
                </el-form-item>
                <el-form-item label="通知图上传" :label-width="formLabelWidth" prop="notice_img" class="carousel-item">
                    <div>
                        <el-button type="primary" v-model="addNoticeForm.notice_img" class="upload-btn" size="medium">
                            <div class="icon">
                                <i class="el-icon-circle-plus"></i>
                                <span>点击上传图片</span>
                            </div>
                            <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeNoticeFile($event)">
                        </el-button>
                        <div class="up-img-show" v-if="addNoticeForm.notice_img">
                            <div class="afresh-upload">
                                <div class="bg"></div>
                                <span class="afresh-span">重新上传</span>
                                <input type="file" accept="image/jpg, image/jpeg, image/png" class="afresh-input" @change="changeNoticeFile($event)">
                            </div>
                            <img :src="addNoticeForm.notice_img" alt />
                        </div>
                    </div>
                    <span class="prompt-msg" style="margin-top: 10px;">建议尺寸：<span class="em">280*200</span></span>
                </el-form-item>
                <el-form-item label="通知内容" prop="content" :label-width="formLabelWidth">
                    <editor :init="init" v-model="addNoticeForm.content"/>
                </el-form-item>
                <el-form-item label="文章排序" prop="sort" :label-width="formLabelWidth" class="carousel-item">
                    <el-input-number v-model="addNoticeForm.sort"
                                     placeholder="排序区间：1-255"
                                     :min="1"
                                     :max="255"
                                     :controls="false"
                                     :precision="0"
                                     class="input-number">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="截止时间" prop="end_time" :label-width="formLabelWidth">
                    <el-date-picker
                            v-model="addNoticeForm.end_time"
                            type="datetime"
                            placeholder="选择日期时间"
                            style="width: 478px;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="推荐首页" :label-width="formLabelWidth">
                    <el-switch
                            v-model="addNoticeForm.is_index"
                            active-color="#20C2C9"
                            inactive-color="#CFD8E6">
                    </el-switch>
                </el-form-item>
                <el-form-item class="item-footer">
                    <el-button class="btn btn-hover" @click="goBack">返回</el-button>
                    <el-button class="btn btn-hover" @click="resetNoticeForm('addNoticeForm')">重置</el-button>
                    <el-button class="btn btn-green" type="primary" @click="saveNotice('addNoticeForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-scrollbar>
    </div>
</template>

<script>
    import {noticeUploadNoticeImg, noticeAdd, noticeEdit, noticeGet} from '../../../utils/apis'
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: "AddNotice",
        data() {
            return {
                //富文本框设置
                init: {
                    menubar: false,
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    toolbar_drawer: "sliding",
                    // quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                    plugins: "lists advlist emoticons fullscreen table insertdatetime wordcount image",
                    language: 'zh_CN', //本地化设置
                    base_url: './tinymce/',
                    height: 500,
                    // //上传图片
                    images_upload_handler: function (blobInfo, success,failure,progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'case/content/image');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization",localStorage.getItem('adminToken'));

                        xhr.upload.onprogress = function(e){
                            progress(e.loaded / e.total * 100);
                        }

                        xhr.onload = function() {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, { remove: true });
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300 ) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('image', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
                //新增通知表单数据
                formLabelWidth: '110px',
                addNoticeForm: {
                    id: '',
                    title: '',
                    notice_img: '',
                    imageFile: '',
                    content: '',
                    sort: '',
                    end_time: '',
                    is_index: false
                },
                addNoticeRules: {
                    title: [
                        { required: true, message: '请输入标题1-30字', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
                    ],
                    notice_img: [
                        { required: true, message: '请上传通知图', trigger: 'change' }
                    ],
                    content: [
                        {required: true, message: '请输入通知内容', trigger: 'blur'}
                    ],
                    sort: [
                        {required: true, message: '请输入文章排序', trigger: 'blur'}
                    ],
                    end_time: [
                        { type: 'date', required: true, message: '请选择截止时间', trigger: 'change' }
                    ],
                }
            }
        },
        components: {
            'editor': Editor
        },
        created() {
            if (this.$route.query.id) {
                this.getNoticeDetail();
            }
        },
        methods: {
            //获取通知详情
            getNoticeDetail() {
                let params = {
                    type: '2',
                    notice_id: this.$route.query.id
                }
                noticeGet(params).then((res) => {
                    this.addNoticeForm.id = res.data.notice_id;
                    this.addNoticeForm.title = res.data.title;
                    this.addNoticeForm.content = res.data.content;
                    this.addNoticeForm.sort = res.data.sort;
                    this.addNoticeForm.notice_img = res.data.pic_url_path.filepath;
                    let date = new Date(res.data.end_time*1000);
                    let time = date.getTime();
                    this.addNoticeForm.end_time = time;
                    if (res.data.is_index) {
                        this.addNoticeForm.is_index = true;
                    } else {
                        this.addNoticeForm.is_index = false;
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            //上传新闻图
            changeNoticeFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.addNoticeForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    let img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.width === 280 && img.height === 200) {
                            let formData = new FormData();
                            formData.append('notice_img', that.addNoticeForm.imageFile);
                            noticeUploadNoticeImg(formData).then((res) => {
                                that.$message.success(res.msg);
                                that.addNoticeForm.notice_img = res.data.filepath;
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            that.$message({
                                type: "error",
                                message: "图片尺寸必须为 280*200",
                            });
                        }
                    }
                }
                event.target.value = '';
            },
            //提交
            saveNotice(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addNoticeForm.id) {
                            let formData = new FormData();
                            formData.append('notice_id', this.addNoticeForm.id);
                            formData.append('title', this.addNoticeForm.title);
                            formData.append('sort', this.addNoticeForm.sort);
                            formData.append('pic_url', this.addNoticeForm.notice_img);
                            formData.append('content', this.addNoticeForm.content);
                            formData.append('end_time', this.addNoticeForm.end_time / 1000);
                            if (this.addNoticeForm.is_index) {
                                formData.append('is_index', 1);
                            } else {
                                formData.append('is_index', 0);
                            }
                            noticeEdit(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.$router.push({
                                    path: '/stateManage/index',
                                    query: {
                                        type: 1
                                    }
                                })
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            let formData = new FormData();
                            formData.append('title', this.addNoticeForm.title);
                            formData.append('sort', this.addNoticeForm.sort);
                            formData.append('pic_url', this.addNoticeForm.notice_img);
                            formData.append('content', this.addNoticeForm.content);
                            formData.append('end_time', this.addNoticeForm.end_time / 1000);
                            if (this.addNoticeForm.is_index) {
                                formData.append('is_index', 1);
                            } else {
                                formData.append('is_index', 0);
                            }
                            noticeAdd(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.$router.push({
                                    path: '/stateManage/index',
                                    query: {
                                        type: 1
                                    }
                                })
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    }
                });
            },
            //重置
            resetNoticeForm(formName) {
                this.$refs[formName].resetFields();
            },
            //返回
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .carousel-content {
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;
        padding: 0 0 0 20px;
        box-sizing: border-box;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .carousel-title {
            border-bottom: 1px solid #dcdcdc;
            padding: 30px 0 20px;
            margin-right: 20px;
            box-sizing: border-box;
            font-size: 16px;
            color: #222;
        }
        .carousel-box {
            padding: 30px 20px 0 0;
            .carousel-item {
                .el-form-item__label {
                    color: #666;
                    font-size: 16px;
                }
                .input-number {
                    width: 478px;
                }
                ::v-deep .el-input-number.is-without-controls .el-input__inner {
                    text-align: left;
                }
            }
            .item-footer {
                text-align: center;
            }
            .btn {
                padding: 10px 30px;
            }
            /*.btn-hover {*/
                /*&:hover {*/
                    /*color: #797171;*/
                    /*border-color: #7fedf3;*/
                    /*background-color: #b6eef1;*/
                /*}*/
            /*}*/
        }
    }
    .upload-btn {
        position: relative;
        width: 280px;
        height: 200px;
        background: #F2F2F2;
        border-color: transparent;
        .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .el-icon-circle-plus {
                color: #FF0013;
                font-size: 26px;
            }
            span {
                font-size: 14px;
                color: #666666;
                margin-top: 10px;
            }
        }
        input {
            width: 280px;
            height: 200px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
        .el-icon-plus {
            color: #FF7128;
            font-size: 30px;
        }
    }
    .prompt-msg {
        color: #666;
        font-size: 16px;
        .em {
            color: #FF0101;
        }
    }
    .up-img-show {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 200px;
        background-color: #fff;
        border: 1px solid #bfbfbf6b;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        video {
            width: 100%;
            height: 100%;
        }
        .afresh-upload {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 30px;
            .bg {
                width: 100%;
                height: 100%;
                background-color: #333;
                opacity: 0.5;
            }
            .afresh-span {
                position: absolute;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                color: #fff;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
            .afresh-input {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }
        }
    }
</style>